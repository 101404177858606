// import { PrimaryButton } from "../uielements/Buttons";
import NoBubbleLayout from "../Layout/NoBubbleLayout";
import RaaisaSchedule from "../../assets/Raaisa_schedule.jpeg";

const Program = () => {
  return (
    <NoBubbleLayout id="program" heading={"Program Schedule"}>
      <div className="flex flex-col items-center mt-[2em]">
        <img
          className="max-w-[660px] w-full shadow-lg"
          src={RaaisaSchedule}
          alt="Schedule"
        />
      </div>
    </NoBubbleLayout>
  );
};

export default Program;
