import React from "react";

const TableOfDates = ({ tableDataSet }) => {
  return (
    <div className="w-full flex flex-col border-[2px] border-secondary rounded-[0.5em] overflow-hidden shadow-sm shadow-bg4 max-w-[1500px] m-auto">
      <h1 className="text-[1em] font-semibold text-center w-full bg-secondary text-tertiary py-[0.8em]">
        Some important dates
      </h1>
      {tableDataSet.map((data, i) => (
        <div
          className="flex border-y-[1px] border-gray-200 text-[0.8em] divide-x-2 divide-gray-300"
          key={i}
        >
          <p className="flex-1 text-center bg-bg4 text-secondary py-[0.5em] px-[0.2em] font-semibold">
            {data.event}
          </p>
          <div className="flex-1 text-center py-[0.5em] px-[0.5em] font-semibold">
            {data.crossDate && (
              <p className="text-red-600 text-[0.8em] line-through">
                {data?.crossDate}
              </p>
            )}
            <p>{data.date}</p>
          </div>
        </div>
      ))}
      <div className="flex border-y-[1px] border-gary-100 text-[0.8em] divide-x-2 divide-gray-300 py-[0.3em]">
        {/* <button
          className="enabled:primary_btn disabled:disabled_btn py-[0.5em] px-[1.2em] m-auto rounded-[0.5em] enabled:shadow-lg enabled:shadow-gray-400"
        >
          Feature comming soon
        </button> */}
      </div>
    </div>
  );
};

export default TableOfDates;
