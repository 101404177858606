import React from "react";
import SpeakerCard from "../SpeakerCard";
import { speakers } from "../../Data/dummy";
import NoBubbleLayout from "../Layout/NoBubbleLayout";

const InvitedSpeakers = () => {
  return (
    <NoBubbleLayout id="invited-speaker" heading={"Invited speakers"}>
      <div className="w-full bg-gray-200 p-[20px] mt-[3vw] rounded-[10px] flex flex-row flex-wrap gap-[20px] justify-evenly items-center">
        {speakers.length > 0 ? (
          speakers?.map((speaker, i) => (
            <SpeakerCard key={i} speaker={speaker} />
          ))
        ) : (
          <p>No speaker invited</p>
        )}
      </div>
    </NoBubbleLayout>
  );
};

export default InvitedSpeakers;
