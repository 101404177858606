import React from "react";
import Heading from "../Heading";
import { Link } from "react-router-dom";
import { PrimaryLinkButton } from "../uielements/Buttons";

const AccountDetails = () => {
  return (
    <section className="no_bubble_layout">
      <Heading
        title={"Payment Details"}
        colorClass={"text-secondary md:text-[35px] sm:text-[25px] text-[16px]"}
      />
      <div className="flex flex-col items-center pt-[2em] gap-[0.5em]">
        <PrimaryLinkButton
          // href={"https://uem.edu.in/raaisa-online-payment/"}
          target={"_blank"}
        >
          Online Registration Closed
        </PrimaryLinkButton>
      </div>
    </section>
  );
};

export default AccountDetails;
