import React, { useEffect } from "react";
import {
  Heading,
  PaymentDetails,
  PrimaryLinkButton,
  Registration,
} from "../components";

const RegistrationPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Registration />
      <PaymentDetails />
      <OnlineForm />
    </div>
  );
};

export default RegistrationPage;

const OnlineForm = () => {
  return (
    <section className="no_bubble_layout">
      <Heading
        title={"Online Registration Form"}
        colorClass={"text-secondary md:text-[35px] sm:text-[25px] text-[16px]"}
      />
      <div className="flex flex-col items-center pt-[2em] gap-[0.5em]">
        <PrimaryLinkButton
          // href={"https://forms.gle/fqWoJ3dWjB8Uo57s6"}
          target={"_blank"}
        >
          Online Registration Closed
        </PrimaryLinkButton>
      </div>
    </section>
  );
};
