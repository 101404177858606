export const PrimaryButton = ({ children, onClick, className, ...props }) => {
  return (
    <button
      disabled={props.disabled ?? !onClick ?? props.type !== "submit"}
      className="size_text py-[0.5em] px-[1.5em] rounded-[0.2em] shadow-md secondary_btn disabled:bg-gray-200 disabled:pointer-events-none disabled:text-gray-500"
      onClick={onClick}
      {...props}
    >
      <p className="text-[0.8em]">{children}</p>
    </button>
  );
};

export const PrimaryLinkButton = ({ children, href, className, ...props }) => {
  return (
    <a
      className={`size_text py-[0.5em] px-[1.5em] rounded-[0.2em] shadow-md ${
        href ? "secondary_btn" : "disabled_btn"
      }`}
      href={href}
      {...props}
    >
      <p className="text-[0.8em]">{children}</p>
    </a>
  );
};
