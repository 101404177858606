import React from "react";
import paperSubImg from "../../assets/RAAISA.png";
import NoBubbleLayout from "../Layout/NoBubbleLayout";
import DownloadTemplate from "./DownloadTemplate";
import { Link } from "react-router-dom";
import springerLogo from "../../assets/springer_transparent.png";
import { PrimaryButton } from "../uielements/Buttons";

const PaperSubmission = () => {
  const handleClick = () => {
    window.location = "https://cmt3.research.microsoft.com/RAAISA2023/";
  };
  return (
    <NoBubbleLayout id="paper-submission" heading={"Paper Submission"}>
      <div className="w-max m-auto mt-[2em]">
        {/* <button
          className="m-auto py-[0.2em] px-[1em] rounded-[0.2em] bg-gray-300 shadow-md  text-gray-500 disabled:disabled_btn enabled:primary_btn"
          disabled
        >
          Feature comming soon
        </button> */}
      </div>
      <div className="w-full flex flex-col justify-center items-center gap-y-[2em]">
        <img
          src={paperSubImg}
          alt="CFP Poster"
          className="max-w-[660px] w-full shadow-lg"
        />
        <DownloadTemplate />
        <SpringerGuildLine />
        {/* <button
          className="m-auto py-[0.2em] px-[1em] rounded-[0.2em] bg-gray-300 shadow-md  text-gray-500 disabled:disabled_btn enabled:secondary_btn"
          onClick={handleClick}
          // disabled
        >
          Click here to Submit
        </button> */}
        <PrimaryButton>Submission Closed</PrimaryButton>
      </div>
    </NoBubbleLayout>
  );
};

export default PaperSubmission;

const SpringerGuildLine = () => (
  <div className="flex xs:flex-row flex-col items-center gap-x-[2em] gap-[0.2em]">
    <div>
      <img src={springerLogo} alt="springer logo" className="w-[10em]" />
    </div>
    <p className="text-[0.8em] w-full max-w-[700px] xs:text-start text-center">
      All accepted & presented papers of "RAAISA 2023" will be published in the
      book series “Innovations in Sustainable Technologies and Computing” (
      <Link
        to="https://www.springer.com/series/17103"
        target="_blank"
        className="text-blue-800 hover:text-blue-600 hover:underline"
      >
        https://www.springer.com/series/17103
      </Link>
      )
    </p>
  </div>
);
