import React from "react";
import TableOfDates from "../TableOfDates";
import { importantDates } from "../../Data/dummy";
import NoBubbleLayout from "../Layout/NoBubbleLayout";

const AuthorsGuidLine = () => {
  return (
    <NoBubbleLayout id="call-for-papers" heading={"Author's Guidlines"}>
      <div className="mt-[2em] text-justify">
        {/* <h1 className="text-[1.2em] font-semibold">Author's Guidelines:</h1> */}
        <p className="text-[1em] mt-[1em] text-justify">
          Each paper must be original and unpublished work, not submitted for
          publication elsewhere (copyright infringement issues will be the
          responsibility of the authors). Papers must be written in good
          english. The submissions must be anonymous, i.e. without author
          name(s), affiliation(s), acknowledgements, or obvious references.
        </p>
        <ul className="call_papers_list">
          <li>
            It should begin with a title, a short abstract, and a list of key
            words and its introduction should summarize the contributions of the
            paper. The similarity index for a submitted paper should be below
            10% with not more than 4% from single source.
          </li>
          <li>
            Papers should be atleast 10 pages and atmost 12 pages in total,
            including references and appendices, using 12 "Times New Roman" font
            size with bold headings and reasonable margins. Extra charges of
            Rs.300/- or USD 5 per page is required after 10 pages and maximum
            page limit can be 12.
          </li>
          <li>
            Submitted papers must be in PDF format and should be submitted
            electronically via Microsoft CMT. Atleast one full paper
            registration will be required for every individual accepted paper.
          </li>
        </ul>
        {/* <button className="px-[0.8em] py-[0.4em] bg-secondary border-[0.2em] border-gray-300 text-tertiary mt-[1.5em] rounded-[0.5em] hover:bg-tertiary hover:text-secondary shadow-md">
          Click For More
        </button> */}
      </div>

      <div className="mt-[2em] text-justify">
        <h1 className="text-[1.2em] font-semibold">
          Review Process of International Conference on Recent Advances in
          Artificial Intelligence & Smart Applications (RAAISA 2023):
        </h1>
        {/* <p className="text-[1em] mt-[1em] text-justify">
          Each paper must be original and unpublished work, not submitted for
          publication elsewhere (copyright infringement issues will be the
          responsibility of the authors). Papers must be written in good
          English. The submissions must be anonymous, i.e. without author
          name(s), affiliation(s), acknowledgements, or obvious references.
        </p> */}
        <ul className="call_papers_list">
          <li>
            There is call for paper with deadline in terms of submission,
            acceptance, review of revised submission and camera-ready
            submission. The Editorial board has decided to implement desk review
            and rejection by the editorial board. On scrutinizing the papers by
            the editorial board if the papers rejected in the process of desk
            review that will not assign any review further. All submissions will
            be screened for plagiarism by iThenticate® / Turnitin, and if
            similarity index exceed the mentioned border line, the submissions
            by the same author will be rejected at any stage of the conference
            even after the conference and after the publication of the
            proceedings online from the springer. The question of reimbursement
            of registration fees will not be applicable here.
          </li>
          <li>
            Names and Affiliations of the authors must NOT be included anywhere
            in the paper during the initial submission. Any attempt to reveal
            author details and affiliation at the time of initial submission
            will lead to rejection summarily of the paper without further
            processing. The review will be fully Double Blind in nature and more
            than two domain experts will be assigned for each paper.
          </li>
          <li>
            All papers that conform to submission guidelines will be evaluated
            based on originality, technical and/or research content/depth,
            correctness, relevance to conference, contributions, and
            readability. Acceptance of papers will be communicated to authors by
            submission portal. The authors of the accepted papers will be
            allowed to make corrections in accordance with the suggestions of
            the reviewers and submit final camera-ready papers within the
            stipulated deadline. All camera ready papers must be prepared in
            conformance with reviewer’s report. Editorial Board or the
            Publisher’s decision would be final.
          </li>
        </ul>
        {/* <button className="px-[0.8em] py-[0.4em] bg-secondary border-[0.2em] border-gray-300 text-tertiary mt-[1.5em] rounded-[0.5em] hover:bg-tertiary hover:text-secondary shadow-md">
          Click For More
        </button> */}
        <section className="w-full mt-[5em]">
          <TableOfDates tableDataSet={importantDates} />
        </section>
      </div>
    </NoBubbleLayout>
  );
};

export default AuthorsGuidLine;
