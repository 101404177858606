import React from "react";

const DataTable2 = ({ tableDataSet }) => {
  const { tableHeads, dataSet } = tableDataSet;
  return (
    <div className="w-full border-[2px] bg-white rounded-[1em] overflow-hidden">
      <div className="w-full sm:flex hidden flex-row bg-gray-700 divide-[2px] overflow-hidden divide-x-[2px] divide-gray-200">
        {tableHeads.map((head, i) => (
          <div
            key={i}
            className={`${head.flexLength} text-[1.2em] text-tertiary px-[0.5em] `}
          >
            <h1>{head.title}</h1>
          </div>
        ))}
      </div>
      <div className="w-full flex flex-col divide-[2px] overflow-hidden divide-y-[2px] divide-gray-200 text-gray-900">
        {dataSet.map((data, i) => (
          <div
            className="flex sm:flex-row flex-col divide-[2px] divide-x-[2px] divide-gray-200"
            key={i}
          >
            <div
              className={`${tableHeads[0].flexLength} px-[0.5em] py-[0.2em] text-[1em] sm:text-[0.9em] overflow-hidden text-ellipsis sm:font-normal  font-semibold`}
            >
              <span className="table_mobile_header">
                {`${tableHeads[0].title} - `}
              </span>
              {data.name}
            </div>
            <div
              className={`${tableHeads[1].flexLength} px-[0.5em] py-[0.2em] text-[1em] sm:text-[0.9em] overflow-hidden text-ellipsis`}
            >
              <span className="table_mobile_header">
                {`${tableHeads[1].title} - `}
              </span>
              {data.affiliation}
            </div>
            <div
              className={`${tableHeads[2].flexLength} px-[0.5em] py-[0.2em] text-[1em] sm:text-[0.9em] overflow-hidden text-ellipsis`}
            >
              <span className="table_mobile_header">
                {`${tableHeads[2].title} - `}
              </span>
              {data.country}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DataTable2;
