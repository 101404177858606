import React from "react";
import logo from "../../assets/uem_logo.png";
import { Link } from "react-router-dom";
import { IoIosMail } from "react-icons/io";

const NavTopSection = () => {
  return (
    <div className="flex flex-col lg:text-[20px] md:text-[16px] xs:text-[12px] text-[8px] leading-[1.1] bg-secondary">
      <div className="flex flex-row justify-center items-center h-auto xs:py-[0.1em] py-[0.3em] px_layout">
        <div className="flex justify-center h-full w-max bg-red">
          <img src={logo} alt="Uem logo" className="topsec_img" />
        </div>

        <p className="text-tertiary font-normal xs:text-[1.8em] text-[2em] mx-[1em] text-center w-full">
          University of Engineering and Management, Kolkata
        </p>

        <div className="flex justify-center h-full w-max bg-red ml-auto">
          <img src={logo} alt="Uem logo" className="topsec_img" />
        </div>
      </div>
      <marquee className="text-[0.8em] font-medium  text-tertiary py-[2px] sm:mx-[60px] xs:mx-[40px] mx-[20px]">
        {" "}
        <p className="flex items-center">
          <span className="uppercase flex gap-[3px] mr-[10px] text-[#FCF7D4]">
            <IoIosMail className="text-[1.2em]" />
            Acceptence/Rejection notification has been sent to all the primary
            authors, please check your respective mailbox.
            <IoIosMail className="text-[1.2em]" />
          </span>
          All accepted & presented papers of "RAAISA 2023" will be published in
          the book series “Innovations in Sustainable Technologies and
          Computing” (
          <Link
            to="https://www.springer.com/series/17103"
            target="_blank"
            className="text-[#FCF7D4] hover:text-blue-400 hover:underline"
          >
            https://www.springer.com/series/17103
          </Link>
          )
        </p>
      </marquee>
    </div>
  );
};

export default NavTopSection;
