import React, { useEffect } from "react";
import { AreaOfInterest, BubbleLayout, Home } from "../components";

const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Home />
      <BubbleLayout id={null} heading={"Conference Tracks"}>
        <AreaOfInterest />
      </BubbleLayout>
    </div>
  );
};

export default HomePage;
