import React from "react";
import HeadBanner from "../HeadBanner";
import Heading from "../Heading";
import TableOfDates from "../TableOfDates";
import { importantDates } from "../../Data/dummy";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  const handlePaperSubmission = () => {
    navigate("paper-submission");
  };
  return (
    <section>
      <HeadBanner />
      <section className="no_bubble_layout">
        <div className="flex sm:flex-row flex-col gap-[4em] sm:items-stretch">
          <div className="sm:flex-1 sm:w-auto w-full size_text flex flex-col justify-between ">
            <section>
              <Heading
                title={"About the Conference"}
                colorClass={
                  "text-secondary md:text-[40px] sm:text-[30px] text-[20px]"
                }
              />
              <p className="text-justify mt-[1.5em] text-[1em]">
                Current years have perceived the progression of Artificial
                Intelligence techniques like Smart Agent-based Systems,
                Human-Computer Interaction Technologies, Reinforcement Learning,
                Sentiment Analysis, Recurrent Neural Networks and its
                Applications, Genetic Algorithm Neural Networks and their
                revolutionary implementations in ultra-modern electronic
                gadgets, emerging smart city and industrial automation
                applications. The implementation of deep learning along with the
                enhancement of security, privacy, and trust issues in smart
                computing makes the system smarter. AI-supported Cyber-physical
                systems add robustness to smart networks. Augmented Reality &
                Virtual Reality together form powerful tools to present and
                visualize information and has the potential to transform the
                teaching-learning process. Meanwhile, Machine Learning, the
                subset of Artificial intelligence provides some security and
                privacy assessment tools to the evolving smart system. Apart
                from this, IoT has become a basic need for the human being, as
                this helps in smoothening their life by making their life
                smarter. Including IoT in several sections has given a chance to
                people to create wonders. The 1st International Conference on
                Recent Advances in Artificial Intelligence & Smart Applications
                (RAAISA) 2023 aims at making the academic sector visualize and
                understand the industry and its need, by acting as a bridge
                between the two sectors. We hope this noble approach of ours
                will empower our academicians, researchers, and industry
                personnel to contribute to great scientific research and
                inventions. This conference also has some distinct sessions
                related to innovative applications with Artificial Intelligence,
                Internet of Things etc. The potential conference committee
                members will ensure good research submissions and identify the
                best research proposals to ignite cooperation between academia
                and industry.
              </p>
            </section>
            <section className="mt-[2em]" id="paper-submission">
              <Heading
                title={"Paper Submission"}
                colorClass={
                  "text-secondary md:text-[40px] sm:text-[30px] text-[20px]"
                }
              />
              <div>
                <div
                  // to="/paper-submission"
                  className="w-max m-auto"
                >
                  <button
                    to="/paper-submission"
                    className={`enabled:secondary_btn disabled:disabled_btn text-[0.8em] py-[0.5em] px-[1.5em] rounded-[0.2em] shadow-md  mt-[1em] m-auto `}
                    // onClick={handlePaperSubmission}
                    disabled
                  >
                    Submission Closed
                  </button>
                </div>
              </div>
            </section>
          </div>

          <div className="sm:flex-1 sm:w-auto w-full flex-col flex justify-between sm:gap-[0] gap-[2em]">
            <section>
              <Heading
                title={"About UEM, Kolkata"}
                colorClass={
                  "text-secondary md:text-[40px] sm:text-[30px] text-[20px]"
                }
              />
              <p className="text-justify mt-[1.5em] mb-[1.5em] text-[1em]">
                UEM Kolkata was established in 2014 by Act No 25 of 2014 of the
                Govt of West Bengal under the able leadership of
                Edu-Entrepreneur Prof. Dr. Satyajit Chakrabarti. Being located
                in New Town, the Smart City in Eastern India and very near to
                the Netaji Subhash International Airport, the students of the
                University are exposed to the top corporates. UEM Kolkata has
                stood as one of the top 10 institutes of India including all
                IITs and all NITs of the country in the NPTEL program ranked by
                IIT Kharagpur and IIT Chennai. The IEM UEM group is an acclaimed
                educational group amongst today's industry-centered academic
                training organizations. IEM has set sublime standards in
                addressing the technical and managerial resource shortage in the
                new era of dynamic globalization. The IEM UEM group has risen to
                fame for its strong foundation in teaching and R&D in
                multifaceted areas. It aims to serve the future generation as
                well as the Nation through its commitment towards
                self-sufficiency and unmatchable excellence. The IEM UEM Group
                has opened up the doors for young minds who dare to dream. It
                encourages the spirit of free inquiry and imagination. In this
                temple of learning, dreams take shape. The educational group
                attempts to inculcate a sense of human values and discipline in
                students to make them respectable human beings. It encourages
                learners to learn, realize their potential, and imbibe the best
                practices.
              </p>
            </section>
            <section>
              <Heading
                title={"Important Dates"}
                colorClass={
                  "text-secondary md:text-[40px] sm:text-[30px] text-[20px]"
                }
              />
              <div className="h-[1.5em]" />
              <TableOfDates tableDataSet={importantDates} />
            </section>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Home;
